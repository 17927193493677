module.exports = new function CookieConsent(){
    var self = this;

    function _delegate(){
        if ($('.cookie-consent').length) {
            $('.js-cookie-consent').on('click', function(ev) {
                ev.preventDefault();

                $.get(ev.currentTarget.href, function(data) {
                    $('.cookie-consent').hide();
                });
            });
        }
    }

    self.init = function(){
        _delegate();
    }

    return self;
}
