module.exports = new function AgeChoice(){
    var self = this;

    var _age_blocks;
    var _how_menu_item;
    var _how;
    var _formCreate;

    function _setupDomElements() {
    	_age_blocks = $('.age-block.selectable a');
        _how_menu_item = $('#menu-item-how');
        _how = $('#how');
    }

    function _showForm(event) {
        if(event !== undefined){
            event.preventDefault();
        }

        _age_blocks.each(function() {
        	$(this).parent().removeClass('arrow');
        });

        $(this).parent().addClass('arrow');

        if ($(this).parent().hasClass('age-12-17')) {
            $('.how-info.age-18-24').removeClass('active');
            $('.how-info.age-12-17').addClass('active');
            $('#agechoicefield').val('age-12-17');
            $("#application-create-form").hide();
            _formCreate.setTab(1);
        }
        else if ($(this).parent().hasClass('age-18-24')) {
            $('.how-info.age-12-17').removeClass('active');
            $('.how-info.age-18-24').addClass('active');
            $('#agechoicefield').val('age-18-24');
            $("#application-create-form").show();
            _formCreate.setTab(2);
        }

        /* reset all age images */
        $('.category img').each(function() {
            var orig_image = $(this).attr('src');
            var new_image = orig_image.replace('-active.png','.png');
            $(this).attr('src',new_image);
        });

        /* change image 12-17 active state */
        if ($(this).parent().find('.category img').length > 0) {
            var orig_image = $(this).parent().find('.category img').attr('src');
            var new_image = orig_image.replace('.png','-active.png');
            $(this).parent().find('.category img').attr('src',new_image);
        }
        
        /* nice animation */
        _how.attr("style","");
        _how.slideDown('slow');
        $('body,html').animate({ scrollTop: _how.position().top }, 1000);

    }

    function _delegate(){
        if(_how.length){
            //_age_blocks.on("click",_showForm);
            _how_menu_item.on("click",_showForm);

            $("body").on("click","#show-form",function(e){
                e.preventDefault();
                $("#application-create-form").show();
            })

            $(".age-block.age-12-17, .age-block.age-18-24").css({cursor:"pointer"}).on("click",function(){

                _showForm.apply($(this).find("a")[0]);
            });

            $(".age-block.age-12-17").on("click",function(){
                dataLayer.push({'event': 'custom_event', 'Category': 'CTA', 'Action': 'Click', 'Label': 'Desktop 12-17'});
            });

            $(".age-block.age-18-24").on("click",function(){
                dataLayer.push({'event': 'custom_event', 'Category': 'CTA', 'Action': 'Click', 'Label': 'Desktop 18-12'});
            });
        }
    }

    self.init = function(formCreate){
        _formCreate = formCreate
        _setupDomElements();
        _delegate();
        var hash = document.location.hash
        if(hash== "#how"){
            _showForm();
        }
    }

    return self;
}