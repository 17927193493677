global.$ = require('jquery');
global.jQuery = global.$;

require("./bootstrap.js");

var formCreate = require("./site/form-create.js");
var ageChoice = require("./site/age-choice.js");
var tooltip = require("./site/tooltip.js");
var errors = require("./site/errors.js");
var success = require("./site/success.js");
var go = require("./site/go.js");
var cookieConsent = require("./site/cookie-consent.js");

$(document).ready(function(){
    formCreate.init();
    ageChoice.init(formCreate);
    tooltip.init();
    errors.init();
    success.init();
    cookieConsent.init();
});
