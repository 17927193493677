require("./../vendors/jquery.inputmask.bundle.min");

module.exports = new function FormCreate(){
    var self = this;
    var currentTab;
    var init = true;
    function _checkRequiredFiles(){
        if(_checkDate() || init){
            init = false;
            var data = $("form").serializeArray();
            var obj = {};
            for (var i = 0, l = data.length; i < l; i++) {
                obj[data[i].name] = data[i].value;
            }
            $.ajax({
                method:"post",
                url: "/application/checkrequired",
                data: obj,
                success: function(data){
                    $("label").removeClass("required");
                    for(var key in data){
                        var rules = data[key];
                        for(var i in rules){
                            var rule = rules[i];
                            if(rule == "required"){
                                var label = $("label[for='"+key+"']");
                                label.addClass("required");
                                if(key == "file_one"){
                                    label.parent().show();
                                }
                            }else{
                                if(key == "file_one"){
                                    var label = $("label[for='"+key+"']");
                                    label.parent().hide();
                                }
                            }
                        }
                    }
                }
            })
        }
    }

    function _checkDate(){
        if($("input#birthdate").length <= 0 ) return false;
         return $("input#birthdate").val().length >= 10
    }

    var timeout;
    function _requestCheck(){
        clearTimeout(timeout);
        timeout = setTimeout(_checkRequiredFiles,200);

        _checkAge();
    }

    function _checkAge() {
        console.log(_getAge($("input#birthdate").val()));
        if (_getAge($("input#birthdate").val()) < 13) {
            $('#legal-guardian-group').show();
            $('.js-label-email').hide();
            $('.js-label-email-guardian').show();
        } else {
            $('#legal-guardian-group').hide();
            $('.js-label-email').show();
            $('.js-label-email-guardian').hide();
        }
    }

    function _getAge(dateStr){
        var today = new Date();
        var birthday = dateStr.split('/');
        var birthDate = new Date(birthday[2], (birthday[1] - 1), birthday[0]);
        var age =  today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    function _normalizeMobib(){
        var mobib = $("input#mobib_id");
        var val = mobib.val();
        mobib.val(val.replace(/[^0-9.]/g, ""));
    }

    function _uploadChanged(){
        var filename = $(this).val();
        var segments = filename.split("\\");
        $(this).closest(".file-upload").find(".file-val input").val("../"+segments[segments.length-1]);
    }
    function _delegate(){
        $("input#birthdate").on("keydown keyup change blur",_requestCheck);
        $("input#mobib_id").inputmask("xxxxxxxxxxxxxxxxxxx", {"mask": "9999999999999999999"});
        $(".file-upload-btn input").on("change",_uploadChanged).on("mouseover",function(){
            $(this).parent().addClass("hover");
        }).on("mouseout",function(){
            $(this).parent().removeClass("hover");
        })
        $("input#birthdate").inputmask("d/m/y",{ "placeholder": "dd/mm/yyyy" });

        $("input").on("blur",function(){
            dataLayer.push({'event': 'custom_event', 'Category': 'Application', 'Action': 'Blur', 'Label': $(this).attr('name')});
        })

        $("#firstchild").on("click",function(){
            var checked = $(this).is(':checked');
            var label = checked? "Select checkbox" : "Deselect checkbox";
            dataLayer.push({'event': 'custom_event', 'Category': 'Application', 'Action': label});
        })

    }

    self.init = function(){
        _checkRequiredFiles();
        _checkAge();
        _delegate();
    }

    self.setTab = function(tab){
        if(tab !== undefined){
            var label = $("label[for='file_one']");
            var group = label.parent()
            if(tab === 1){
                group.hide();
            }
            if(tab === 2){
                group.show();
                label.addClass("required");
            }
        }
    }

    return self;
}

