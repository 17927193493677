module.exports = new function Errors(){
    var self = this;

    var errors;
    var _how;
    var _how_position;

    function _setupDomElements() {
        _how = $('#how');
    }

    function _delegate(){
        
        if (typeof(hasErrors) != "undefined") {
            if (hasErrors) {
                _how.show();
                $('body,html').animate({ scrollTop: _how.position().top }, 1000);
            }
        }

        if (typeof(agechoiceform) != "undefined") {
            if (agechoiceform == 'age-12-17') {
                $('.age-block.age-12-17').addClass('arrow');
            }
            if (agechoiceform == 'age-18-24') {
                $('.age-block.age-18-24').addClass('arrow');
            }
        }
    }

    self.init = function(){
        _setupDomElements();
        _delegate();
    }

    return self;
}