
        var lang = "nl";
        var contItem = 0;
        var StringPaymentMode = "Mode de payement disponible.";
        var StringPaymentMode_nl = "Beschikbare betalingswijzen.";
    
        var seviceURL = "http://www.stib-mivb.be/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.pos_next";
        var tab1="\u00e8,(,)";
        var tab2="e, , ";
        var rep2=tab1.split(',');
        var rep=tab2.split(',');
        var myarray=new Array();
        var myCurrenti=-1;

        /*
while(rep2[++myCurrenti]){
            myarray[rep2[myCurrenti]]=rep[myCurrenti]
        }
*/
        myarray['Œ']='OE';
        myarray['œ']='oe';
        
        var x = $("#localisationNode")[0];
        
        //functions
        function callNewMap(pIdMap, pLog, pLat, pIdType){
            L.mapbox.accessToken = 'pk.eyJ1Ijoic2ViaWNibyIsImEiOiIzeG9EU1lFIn0.DTEXtxw-Qtx5xyQ_NLszQQ';
            var geocoder = L.mapbox.geocoder('mapbox.places-v1'),
                map = L.mapbox.map(pIdMap, 'sebicbo.k1fln2ln').setView([pLat, pLog], 16);
                map.scrollWheelZoom.disable();
                var myIcon = L.icon({
                        iconUrl: '/assets/img/ico-5.gif',
                        iconRetinaUrl: '/assets/img/ico-5.gif',
                        iconSize: [30, 40],
                        iconAnchor: [15, 35],
                        popupAnchor: [-3, -76],
                        shadowSize: [68, 95],
                        shadowAnchor: [22, 94]
                    });

                L.marker([pLat, pLog], {icon: myIcon}).addTo(map);
            //geocoder.query([pLat, pLog], showMap);
            
            function showMap(err, data) {
                // The geocoder can return an area, like a city, or a
                // point, like an address. Here we handle both cases,
                // by fitting the map bounds to an area or zooming to a point.
                if (data.lbounds) {
                    map.fitBounds(data.lbounds);
                    map.setView([data.latlng[0], data.latlng[1]], 2);
                } else if (data.latlng) {
                    map.setView([data.latlng[0], data.latlng[1]], 2);
                }
            }
        }
        function initSwitchCharts(){
            while(rep2[++myCurrenti]){
                myarray[rep2[myCurrenti]]=rep[myCurrenti]
            }
        }
        function exitSepcialCharts(pChaine){
         return pChaine.replace(/./g, function($0){return (myarray[$0])?myarray[$0]:$0 })
        }
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            } else { 
                x.innerHTML = "Geolocation is not supported by this browser.";
            }
        }
        function drawShop(pObjectPOS){
            return;
        }
        function showPosition(position) {
            callShop(position, "1");
            callShop(position, "2");
            callShop(position, "5");
            callShop(position, "7");
        }
        function createParameter(pName, pValue){
            var parameter = {
                string_Name : pName,
                value  : pValue
            }
            return parameter;
        }
        function callShop(position, pPostype){
            window['request'+pPostype] =  $.ajax({
                type: "GET",
                url: "//www.stib-mivb.be/irj/servlet/prt/portal/prtroot/pcd!3aportal_content!2fSTIBMIVB!2fWEB_F_Internet!2fWEB_F_iViews!2fcom.stib.CORS!2fcom.stib.ProxyCors_storelocator",
                data: { 
                    origin: current_site,
                    language: site_lang, 
                    longitude:position.coords.longitude, 
                    latitude:position.coords.latitude, 
                    postypes: pPostype, 
                    ticket_types:"" 
                }
            })

            if(pPostype == "5"){
                window['request'+pPostype].done(function( data ) {
                    $("#go-page").removeClass("loading");
                    $.each(data, function(i, item) {
                        var subCount = 0
                        $.each(item, function(j, itemsub) {
                            var myBreak = false;
                            subCount ++
                            contItem ++;
                            var widthCol = 0;
                            switch(pPostype) {
                                case "7":
                                    widthCol = 12;
                                    break;
                                case "2":
                                    widthCol = 6;
                                    break;
                                case "5":
                                    widthCol = 4;
                                    break;
                                default:
                                    widthCol = 4;
                            }
                            var currentPaymentMode = (lang == "fr")?StringPaymentMode:StringPaymentMode_nl;
                            var currentBuldingshopCard = '';
                            currentBuldingshopCard += '<article class="row-eq-height col-xs-'+widthCol+'"> <div class="content"> <h1>'+itemsub.store_name+'</h1>';
                            
                            currentBuldingshopCard += '<address>';
                            currentBuldingshopCard += '<strong>'+itemsub.store_name+'</strong><br>';
                            currentBuldingshopCard += itemsub.store_address+'<br>';
                            currentBuldingshopCard += itemsub.store_city+', '+itemsub.store_postal_code+'<br>';
                            currentBuldingshopCard += '</address>';
                            currentBuldingshopCard += '<p>'+currentPaymentMode+'</p>';
                            currentBuldingshopCard += '<ul class="paymentMode">';
                            for(x in itemsub.payment){
                                currentBuldingshopCard += '<li class="'+exitSepcialCharts(itemsub.payment[x]).split(" ")[0]+'">'+itemsub.payment[x]+'</li>';
                            }
                            currentBuldingshopCard += '</ul>';
                            currentBuldingshopCard += '</div>';

                            var currentMap = 'map'+contItem;
                            currentBuldingshopCard += '<div class="map" id='+currentMap+'></div>';

                            switch(pPostype) {
                                case "5":
                                    $( ".row.go .row" ).append( currentBuldingshopCard );
                                    $(".row.go .elementPart .container.visuLoader").hide();
                                    widthCol = 4;
                                    myBreak = (subCount>=3)?false:true;
                                    break;
                                default:
                                break;
                            }
                            
                            callNewMap(currentMap, itemsub.store_longitude, itemsub.store_latitude, pPostype); 
                             // Will stop running after "three"
                             return (myBreak);
                        });
                        //$(".container .row .elementPart .row article .content").eqHeight();
                    });         
                });
            }
        }
        function getXDomainRequest() {
            var xdr = null;
            
            if (window.XDomainRequest) {
                xdr = new XDomainRequest(); 
            } else if (window.XMLHttpRequest) {
                xdr = new XMLHttpRequest(); 
            } else {
                alert("Votre navigateur ne gère pas l'AJAX cross-domain !");
            }
            
            return xdr; 
        }
        function getQueryParams(qs) {
            qs = qs.split("+").join(" ");
            var params = {},
                tokens,
                re = /[?&]?([^=]+)=([^&]*)/g;
        
            while (tokens = re.exec(qs)) {
                params[decodeURIComponent(tokens[1])]
                    = decodeURIComponent(tokens[2]);
            }
        
            return params;
        }
        function myLanguage(){
            if (navigator.appName == 'Netscape'){
                lang = navigator.language;
                }
            else{
                lang = navigator.browserLanguage;
                }
            lang = (lang.length > 2)?lang.substring(0,2):lang;
                return lang;
        }
//      $( getLocation )
        
        $( document ).ready(function() {
            if($("#go-page").length){
                $('.'+myLanguage()).show();
                initSwitchCharts();
                getLocation();
            }
        });