module.exports = new function Tooltip(){
    var self = this;

    var tooltip;

    function _delegate(){
        $('[data-toggle="tooltip"]').tooltip({
            trigger : 'manual',
            template : '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="close-button"><a href="#"><span class="icon-disapprove"></span></a></div><div class="tooltip-inner"></div></div>'
        });

        $(document).on("click", '[data-toggle="tooltip"]',function(e) {
          $(this).tooltip("show");
        });

        $(document).on("click", ".close-button a",function(e) {
          e.preventDefault();
          $(this).parents('.tooltip').tooltip("hide");
        });
    }

    self.init = function(){
        _delegate();
    }

    return self;
}